const suggestions = [
  {
    icon: "dollar-sign",
    text: "Pull me my last 5 deals",
  },
  {
    icon: "calendar",
    text: "Pull me a list of all available Property Statuses",
  },
  {
    icon: "users",
    text: "Find me information about client: Alexander",
  },
  {
    icon: "magic",
    text: "How many clients lives in Berlin ?",
  },
]

const typewriterSpeed = 5

const purifyConfig = {
  ALLOWED_TAGS: [
    "p",
    "br",
    "b",
    "i",
    "em",
    "strong",
    "a",
    "ul",
    "ol",
    "li",
    "code",
    "pre",
    "blockquote",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "hr",
    "table",
    "thead",
    "tbody",
    "tr",
    "th",
    "td",
  ],
  ALLOWED_ATTR: ["href", "target", "class"],
  ALLOW_DATA_ATTR: false,
  ADD_ATTR: [["target", "_blank"]],
  FORBID_TAGS: ["style", "script", "iframe"],
  FORBID_ATTR: ["style", "onerror", "onload"],
}

const scrollThresholds = {
  allowLoadMoreMessages: 10,
  loadMoreMessages: 5,
  showScrollToBottomButton: 80,
}

const itemsPerPage = 10

const apiTimeout = 30000 // 30 seconds

export { suggestions, typewriterSpeed, purifyConfig, scrollThresholds, itemsPerPage, apiTimeout }
