<template>
  <div class="flex items-center gap-3">
    <div class="text-sm flex-shrink-1 text-left">
      {{ t("integrations.extension-propvision.generic.feedback.title") }}
    </div>
    <div class="flex-shrink-0">
      <fa-icon
        aria-label="t('integrations.extension-propvision.generic.feedback.positive')"
        :class="['cursor-pointer p-2', { 'text-green-500': rating === 'positive' }]"
        @click="setRating('positive')"
        name="thumbs-up"
      />
      <fa-icon
        aria-label="t('integrations.extension-propvision.generic.feedback.negative')"
        :class="['cursor-pointer p-2', { 'text-orange-500': rating === 'negative' }]"
        @click="setRating('negative')"
        name="thumbs-down"
      />
    </div>
    <nice-textarea
      class="flex-grow"
      :maxlength="250"
      :minRows="3"
      :maxRows="3"
      :show-word-limit="true"
      v-model="comment"
      :placeholder="t('integrations.extension-propvision.generic.feedback.placeholder')"
    />
    <GradientBorderButton
      @click="submitFeedback"
      class="mr-2 ml-2 flex-shrink-0"
      background="var(--ps-ai-gradient)"
      :disabled="!rating && !comment"
    >
      <fa-icon name="magic"></fa-icon>
      {{ t("integrations.extension-propvision.generic.feedback.submit") }}
    </GradientBorderButton>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import GradientBorderButton from "@/components/integrations/propgen/GradientBorderButton.vue"
import { ImageTypeEnhanced } from "./propgen.types"

const { t } = useI18n()

const { image } = defineProps<{ image: ImageTypeEnhanced }>()

const rating = ref(null as "positive" | "negative" | null)
const comment = ref("")

const setRating = (value: "positive" | "negative") => {
  rating.value = rating.value === value ? null : value
}

const emit = defineEmits(["submit"])

const submitFeedback = () => {
  if (!rating.value && !comment.value) return

  emit("submit", {
    rating: rating.value,
    comment: comment.value,
    image,
  })

  // Reset form after submission
  rating.value = null
  comment.value = ""
}
</script>
