<template>
  <form-dialog
    :title="t(`integrations.extension-propvision.${operation}.dialogTitle`)"
    :submit-button-title="t(`integrations.extension-propvision.generic.action`)"
    @update:visible="emit('update:visible', $event ? operation : null)"
    @close="emit('close')"
    :submitFunc="submitFunc"
    @complete="emit('complete', $event)"
    width="600px"
    append-to-body
    :handleError="false"
    footer-type="bigButton"
  >
    <form-section>
      <span>
        {{
          t(`integrations.extension-propvision.generic.estimatedDuration`, {
            value: t(`integrations.extension-propvision.${operation}.estimatedDuration`),
          })
        }}
      </span>
      <form-row direction="column" class="mt-4">
        <template #label>
          {{ t(`integrations.extension-propvision.image_enhancement.config.enhanceType.title`) }}
          <nice-tooltip
            :content="t('integrations.extension-propvision.image_enhancement.config.enhanceType.helpTooltip')"
          >
            <fa-icon class="text-primary" name="info-circle" />
          </nice-tooltip>
        </template>
        <nice-select clearable v-model="config.enhance_type" :options="translatedEnhanceOptions.enhanceType" />
      </form-row>
      <form-row
        direction="column"
        :title="t(`integrations.extension-propvision.image_enhancement.config.cloudType.title`)"
        class="mt-4"
      >
        <nice-select clearable v-model="config.cloud_type" :options="translatedEnhanceOptions.cloudType" />
      </form-row>
    </form-section>
  </form-dialog>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, unref } from "vue"
import { enhanceOptions } from "../propgen.constants"
import { ImageTypeEnhanced } from "../propgen.types"
import { useI18n } from "vue-i18n"
import { SubmitOperationParams, SubmitOperationResultParams } from "../PropgenImageOperations.vue"

const { t } = useI18n()
const { images, operation, onSubmit } = defineProps<{
  images: ImageTypeEnhanced[]
  operation: "image_enhancement"
  onSubmit: (params: SubmitOperationParams) => Promise<SubmitOperationResultParams>
}>()

const translatedEnhanceOptions = {
  enhanceType: enhanceOptions.enhanceType.map(({ id }) => ({
    id,
    name: t(`integrations.extension-propvision.image_enhancement.config.enhanceType.values.${id}`),
  })),
  cloudType: enhanceOptions.cloudType.map(({ id }) => ({
    id,
    name: t(`integrations.extension-propvision.image_enhancement.config.cloudType.values.${id}`),
  })),
}

const emit = defineEmits(["update:visible", "submit", "close", "complete"])

const defaults = {
  enhance_type: "neutral",
  cloud_type: null,
  lens_correction: true,
  vertical_correction: false,
}

const config = ref({ ...defaults })

const submitFunc = async () => {
  return onSubmit({ operation, images, config: unref(config.value) })
}
</script>
